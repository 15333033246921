<template>
  <div class="px-4 pt-3 pb-2 d-flex flex-row flex-wrap align-items-start">
    <b-form-select v-model="queryParams.store_id" :disabled="!allowMultiStores" class="mb-2 mr-2" style="width: 90px">
      <b-form-select-option selected disabled hidden :value="null">{{ $tc('general.store', 1) }}</b-form-select-option>
      <b-form-select-option :value="''">{{ $t('general.all') + ' ' + $tc('general.store', 2) }}</b-form-select-option>
      <b-form-select-option :value="1">{{ $t('uk') }}</b-form-select-option>
      <b-form-select-option :value="2">{{ $t('address.mexico') }}</b-form-select-option>
    </b-form-select>
    <date-range-picker
      ref="picker"
      v-model="dateRange"
      :locale-data="{ format: 'yyyy-mm-dd' }"
      :auto-apply="true"
      :timePicker24Hour="true"
      :show-dropdowns="true"
      :ranges="ranges"
      class="mb-2 mr-2"
      style="width: max-content; min-width: 220px"
      v-b-tooltip.hover.top="$t('notify.date_range_notice', { name: $t('draw.draw_date') })" />
    <b-form-input
      type="search"
      v-model="queryParams['title']"
      :placeholder="type === 'entry' ? $t('general.title') : $t('draw.draw_title')"
      @keyup.enter="onSearch"
      class="mb-2 mr-2"
      style="width: 160px" />
    <b-form-select v-model="queryParams['status']" class="mb-2 mr-2" style="width: 102px">
      <b-form-select-option selected disabled hidden :value="null">{{ $t('general.status') }}</b-form-select-option>
      <b-form-select-option :value="0">{{ $t('entry.status_list[0]') }}</b-form-select-option>
      <b-form-select-option :value="1">{{ $t('entry.status_list[1]') }}</b-form-select-option>
    </b-form-select>
    <b-form-input v-model="queryParams['competition_id']" type="search" :placeholder="$t('competition.comp_id')" @keyup.enter="onSearch" class="mb-2 mr-2" style="width: 140px" />
    <button-group :onSearch="onSearch" :onReset="onReset" class="mb-2" />

    <div v-if="type == 'entry'" v-b-modal.publish-manual class="ml-2 mt-1 d-flex align-items-center action">
      <b-icon-question-circle-fill class="mr-2" />
      How to Publish Entry List Manually?
    </div>
    <confirm-modal id="publish-manual" title="How to Publish Entry List Manually?">
      <template #form>
        <p class="mb-0">Begin by finding the competition for which you need to publish the entry list.</p>
        <p class="mt-2 mb-0">
          Click the 'Export Excel' button
          <b-button variant="basic" class="d-inline-block">{{ $t('entry.export_excel') }}<b-icon-file-spreadsheet class="ml-1" /></b-button>
          , which will initiate the download of an Excel file.
        </p>
        <p class="mt-2 mb-0">Verify that the downloaded entry list is accurate and ready for publication.</p>
        <p class="mt-2 mb-0">
          Next, click the upload icon
          <b-button class="d-inline-block"><b-icon-upload /></b-button>
          and either drag the downloaded Excel file into the upload box or select it manually.
        </p>
        <p class="mt-2 mb-0">Once the file is added, click the 'Submit' button to publish the entry list file.</p>
        <p class="mt-2 mb-0">To confirm that the entry list is displayed correctly, navigate to the 'Entry List' page on the competition website.</p>
        <p class="mt-2 mb-0">In case of any issues encountered throughout the process, please contact our IT department for assistance.</p>
      </template>
      <template #footer="{ cancel }">
        <b-button variant="outline-secondary" @click="cancel">Close</b-button>
      </template>
    </confirm-modal>
  </div>
</template>
<script>
import { formatDayEndUtc, formatDayStartUtc, formatLocalDateTime, setupDateRanges } from '@/utils/dateTimeUtils';
import { mapGetters } from 'vuex';

export default {
  name: 'EntrySearchInput',
  props: {
    queryParams: Object,
    onSearch: Function,
    type: String,
  },
  data() {
    return {
      ranges: setupDateRanges(),
    }
  },
  computed: {
    ...mapGetters({ allowMultiStores: 'allowMultiStores', selfStore: 'store' }),
    dateRange: {
      get() {
        return {
          startDate: formatLocalDateTime(this.queryParams.start, 1, false, true),
          endDate: formatLocalDateTime(this.queryParams.end, 1, false, true),
        }
      },
      set(newVal) {
        this.queryParams['start'] = formatDayStartUtc(newVal.startDate, 0, false, true)
        this.queryParams['end'] = formatDayEndUtc(newVal.endDate, 0, false, true)
        return newVal
      },
    },
  },
  methods: {
    onReset(event) {
      // event.preventDefault()
      this.dateRange['startDate'] = null
      this.dateRange['endDate'] = null
      this.queryParams.store_id = this.allowMultiStores ? null : this.selfStore
      this.queryParams.status = null
      this.queryParams.title = null
      this.queryParams.start = null
      this.queryParams.end = null
      this.onSearch()
    },
  },
}
</script>
