<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <div class="px-4 pt-3 pb-2">
      <div v-if="$route.query" class="w-100 d-flex flex-row flex-nowrap align-items-center mb-2">
        {{ $t('competition.comp_title') }}:
        <span class="font-weight-bold ml-1 mr-3">{{ title }}</span>
        <icon-translate v-if="titleLang" classes="mr-3" />
        {{ $t('competition.comp_id') }}:
        <span class="font-weight-bold ml-1 mr-3">{{ id }}</span>
        <CompetitionStatus :value="status" />
      </div>
      <!-- Search Filter Input -->
      <div class="w-100 d-flex flex-row flex-wrap align-items-start" style="gap: 8px">
        <b-form-input
          v-model="queryParams['numbers']"
          type="search"
          :placeholder="$tc('order.ticket_number', 1)"
          @keyup.enter="onSearch"
          style="width: 136px"
          v-b-tooltip.hover="`Use comma to separate multiple ticket numbers`" />
        <b-form-select v-model="queryParams['status']" style="width: 200px">
          <b-form-select-option selected :value="null">{{ $t('status.all_status') }}</b-form-select-option>
          <b-form-select-option v-for="value in [0, 1, 2, 3]" :key="value" :value="value">{{ $t('competition.prize_status')[value] }}</b-form-select-option>
        </b-form-select>
        <b-form-input v-model="queryParams['product_name']" type="search" :placeholder="$t('product.product_name')" @keyup.enter="onSearch" style="width: 136px" />
        <button-group :onSearch="onSearch" :onReset="onReset">
          <template #export>
            <b-button variant="reset" class="ml-2 mr-0" @click="() => onExport(false)" :disabled="isLoading">
              <b-icon-file-spreadsheet class="mr-1" />{{ isLoading ? $t('status.loading') : $t('action.export') }}
            </b-button>
            <b-button variant="reset" class="ml-2" @click="() => onExport(true)" :disabled="isLoading">
              <b-icon-file-spreadsheet class="mr-1" />{{ isLoading ? $t('status.loading') : 'Export Won Products' }}
            </b-button>
          </template>
        </button-group>
        <!-- <b-button variant="primary" v-b-modal.assign-prize><b-icon-trophy /> {{ $t('competition.assign_prize') }}</b-button> -->
      </div>
    </div>

    <b-table :fields="fields.value" :items="items" primary-key="id" show-empty small hover responsive head-variant="light" sticky-header="100%" class="m-0 p-0">
      <template #head()="{ label, field }">{{ label }}<b-icon-question-circle-fill v-if="field.tooltip" class="ml-1" v-b-tooltip.hover.topright="field.tooltip" /></template>
      <template #cell(status)="{ value }">
        <PrizeStatus :value="value" />
      </template>
      <template #cell(prize)="{ value, item }">
        <tooltip-span v-if="item.product_id" :onClick="() => openInstantSetStatus(item)" classes="action" :content="value" />
        <span v-else class="text-uppercase">{{ value }}</span>
      </template>
      <template #cell(type)="{ value }">
        <b-badge pill :variant="['primary', 'warning', 'success', 'danger'][value]">{{ $t('competition.prize_type')[value] }}</b-badge>
      </template>
      <template #cell(order)="{ value, item }">
        <router-link
          v-if="value"
          :to="{
            name: 'Order Details',
            query: { orderDetailsId: value.id, userId: item.user.id, userEmail: item.user.email, display_name: item.user.display_name },
          }"
          class="action text-decoration-none">
          {{ '#' + value.id }}
        </router-link>
        <b-badge v-else pill variant="danger">{{ $t('order.no_order') }}</b-badge>
      </template>
      <template #cell(order.type)="{ value }"><TicketType :value="value" /></template>
      <template #cell(user)="{ value }">
        <span v-if="value" @click="viewUser(value)" class="action">{{ value.name }}</span>
        <b-badge v-else pill variant="danger">{{ $t('user.no_user') }}</b-badge>
      </template>
      <template #cell(action)="{ item }">
        <!-- Only for live draw -->
        <b-button
          v-if="item.type == '1'"
          variant="primary"
          @click="viewSetPrize(item)"
          :disabled="status < 2 || item.status != RWD_STATUS_LIST[0]"
          v-b-tooltip.hover.topleft="$t('competition.assign_prize')">
          <b-icon-trophy />
        </b-button>

        <!-- Only for prize with a product -->
        <template v-if="!!item.product_id">
          <!-- <b-button
            variant="primary"
            @click="viewSetPrize(item, true)"
            :disabled="item.type == 1 ? status < 2 || item.selected != 0 : item.status != 1 || Number(item.convert_cash_amount) > 0 || Number(item.convert_credit_amount) > 0"
            v-b-tooltip.hover.topleft="`Set Prize`">
            <b-icon-cash-coin />
          </b-button> -->
          <b-button
            variant="primary"
            @click="openInstantSetStatus(item, 5)"
            :disabled="item.type == 1 ? status < 2 || item.status != RWD_STATUS_LIST[3] : item.status != RWD_STATUS_LIST[3]"
            v-b-tooltip.hover.topright="`Set as Contacted`">
            <b-icon-telephone-plus />
          </b-button>
          <b-button
            variant="primary"
            @click="openInstantSetStatus(item, 6)"
            :disabled="item.type == 1 ? status < 2 || item.status != RWD_STATUS_LIST[2] : item.status != RWD_STATUS_LIST[2]"
            v-b-tooltip.hover.topright="`Set as Received`">
            <b-icon-truck />
          </b-button>
        </template>
      </template>
      <template #cell(admin)="{ value }">
        <template v-if="value">{{ value }}</template>
        <b-badge v-else variant="danger">{{ $t('admin.no_admin') }}</b-badge>
      </template>
    </b-table>
    <paginate :queries="queryParams" :fetcher="getRewardList" :total="total" showBack class="card-footer" />

    <!-- <AssignPrize v-if="items.length > 0">
      <template #default>
        <div v-if="$route.query" class="w-100 d-flex flex-row flex-nowrap align-items-center mb-2">
          {{ $t('competition.comp_title') }}:
          <span class="font-weight-bold ml-1 mr-3">{{ title }}</span>
          <icon-translate v-if="titleLang" classes="mr-3" />
          {{ $t('competition.comp_id') }}:
          <span class="font-weight-bold ml-1 mr-3">{{ id }}</span>
          <CompetitionStatus :value="status" />
        </div>
      </template>
    </AssignPrize> -->

    <SetPrize v-if="items.length > 0" :reward.sync="setPrizeTarget" :getList="getRewardList" :competitionId="id" :viewUser="viewUser" />

    <InstantWinSetStatus v-if="items.length > 0" :ticket.sync="instantStatusTarget" :getList="getRewardList" isRewardList />

    <b-modal
      lazy
      centered
      id="user-details"
      :title="$t('user.user_details', { id: '' })"
      title-class="w-100 d-flex justify-content-center align-self-center"
      header-class="py-2"
      body-class="pt-0"
      hide-footer
      @hidden="hideModal">
      <UserDetails v-if="userDetailTarget" :user="userDetailTarget">
        <template #address="{ address }">
          <br />
          <AddressDetails :userStore="userDetailTarget.store_id" :address="address" />
        </template>
      </UserDetails>
    </b-modal>
  </card>
</template>
<script>
import CompetitionStatus from '@/components/Competitions/CompetitionStatus.vue'
import InstantWinSetStatus from '@/components/Competitions/InstantWinSetStatus.vue'
import PrizeStatus from '@/components/PrizeStatus.vue'
import TicketType from '@/components/TicketType.vue'
import AddressDetails from '@/components/Users/AddressDetails.vue'
import UserDetails from '@/components/Users/UserDetails.vue'
import SetPrize from '@/components/Winners/SetPrize.vue'
import axios from '@/plugins/axios'
import { API_LIST, RWD_STATUS_LIST } from '@/utils/consts'
import { formatLocalDateTime, formatUtcDateTime } from '@/utils/dateTimeUtils'
import { exportAsExcel } from '@/utils/fileUtils'
import { noTransFormatter, notifyError } from '@/utils/index'
import { formatWithGBP } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'

export default {
  components: {
    CompetitionStatus,
    InstantWinSetStatus,
    PrizeStatus,
    AddressDetails,
    UserDetails,
    SetPrize,
    TicketType,
  },
  data() {
    const titleObj = {}
    const titleVal = noTransFormatter(decodeURIComponent(this.$route.query.title), 'title', titleObj)
    const defaultParams = { numbers: null, status: null, product_name: null, page: 1, perPage: 10 }
    return {
      RWD_STATUS_LIST,
      isLoading: true,
      id: parseInt(this.$route.query.id),
      status: parseInt(this.$route.query.status),
      title: titleVal,
      titleLang: titleObj.titleLang,
      defaultParams: defaultParams, // init value
      queryParams: { ...defaultParams }, // real time value
      localParams: {}, // value passed to every fetch
      userDetailTarget: null,
      instantStatusTarget: null,
      setPrizeTarget: null,
    }
  },
  computed: {
    ...mapGetters({ prizeList: 'request/prizeList' }),
    items: {
      get() {
        return this.prizeList?.data ?? []
      },
      set: (v) => v,
    },
    total() {
      return this.prizeList?.total || 0
    },
    fields() {
      const tmp = [
        { key: 'prize', label: this.$t('competition.prize'), tooltip: this.$t('competition.prod_prize_tip'), stickyColumn: true },
        { key: 'type', label: this.$t('competition.draw_type'), sortable: true },
        {
          key: 'cost',
          label: this.$t('competition.prize_cost'),
          sortable: true,
          formatter: (v) => (v ? formatWithGBP(v) : '-'),
          tdAttr: (v, k, i) => ({ style: Number(i.convert_cash_amount) > 0 || Number(i.convert_credit_amount) > 0 ? 'text-decoration: line-through' : '' }),
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        { key: 'status', label: this.$t('general.status'), sortable: true },
        {
          key: 'convert_cash_amount',
          label: this.$t('competition.converted_cash'),
          sortable: true,
          formatter: (v) => (v ? formatWithGBP(v) : '-'),
          thClass: 'text-center',
          tdClass: 'text-right',
          tooltip: this.$t('competition.converted_tip'),
        },
        {
          key: 'convert_credit_amount',
          label: this.$t('competition.converted_credit'),
          sortable: true,
          formatter: (v) => (v ? formatWithGBP(v) : '-'),
          thClass: 'text-center',
          tdClass: 'text-right',
          tooltip: this.$t('competition.converted_tip'),
        },
        { key: 'user', label: this.$t('winner.winner_name') },
        { key: 'ticket_number', label: this.$tc('order.ticket_number', 1), sortable: true, formatter: (v) => v ?? this.$t('no_data') },
        { key: 'order', label: this.$tc('general.order', 1), sortable: true },
        { key: 'order.type', label: this.$t('general.type'), sortable: true },
        { key: 'action', label: this.$tc('general.action', 1), tooltip: this.$t('instant_win.action_tip') },
        { key: 'admin', label: this.$t('general.updated_by'), formatter: (v, k, i) => (v && v.email ? v.email : i.admin_user_ud) },
        { key: 'updated_at', label: this.$t('general.updated_at'), sortable: true, formatter: (v) => formatLocalDateTime(v) },
      ]
      return { value: tmp, keys: tmp.map((x) => x.key), labels: tmp.map((x) => x.label) }
    },
  },
  methods: {
    formatNumbersInput(obj) {
      if (this.queryParams.numbers) {
        const numbers = this.queryParams.numbers.split(/[^0-9]+/)
        if (numbers && numbers.length >= 1 && numbers[0] !== '') {
          this.$set(obj, 'ticket_numbers', `[${numbers}]`)
          this.$set(obj, 'numbers', undefined)
        }
      }
    },
    getRewardList() {
      this.isLoading = true
      this.localParams = { ...this.queryParams, competition_id: this.id }
      this.formatNumbersInput(this.localParams)
      axios
        .get(API_LIST.get.prizeList, { params: this.localParams })
        .then((res) => {
          this.items = res?.data?.data?.data || []
          this.$store.dispatch('request/mutateState', { property: 'prizeList', with: res?.data?.data })
        })
        .catch((err) => notifyError(err, this.$t('notify.unknown_err')))
        .finally(() => (this.isLoading = false))
    },
    onSearch() {
      this.queryParams.page = 1
      this.getRewardList()
    },
    onReset() {
      this.queryParams = { ...this.defaultParams, perPage: this.queryParams.perPage }
      this.onSearch()
    },
    csvPreProcess(data) {
      for (let i in data) {
        const row = data[i]
        data[i].status = row.status?.replace?.(/'_'/g, ' ')?.toUpperCase?.()
        data[i].cost = row.cost ? formatWithGBP(row.cost) : '-'
        data[i].convert_cash_amount = row.convert_cash_amount ? formatWithGBP(row.convert_cash_amount) : '-'
        data[i].convert_credit_amount = row.convert_credit_amount ? formatWithGBP(row.convert_credit_amount) : '-'
        data[i].order = row.order?.id ? `#${row.order.id}` : this.$t('order.no_order')
        data[i].type = this.$t('competition.prize_types')[row.type] ?? ''
        data[i].user = row.user ? row.user.name : this.$t('user.no_user')
        data[i].admin = row.admin ? row.admin.name : this.$t('admin.no_admin')
        data[i].updated_at = formatLocalDateTime(row.updated_at)
        Object.keys(row).forEach((key) => {
          if (!this.fields.keys.includes(key)) {
            delete data[i][key]
          }
        })
      }
    },
    onExport(won_prize) {
      const params = { ...this.queryParams, competition_id: this.id, page: 1, perPage: 1000 }
      if (won_prize) {
        params['won_prize'] = 1
      }
      this.formatNumbersInput(params)
      return exportAsExcel(
        `PrizeList_#${this.id}_${formatUtcDateTime(undefined, 2, true)}`, // title + generated at
        API_LIST.get.prizeList,
        params,
        this.total,
        this.csvPreProcess,
        this.fields.keys,
        [this.fields.labels],
        (bool) => (this.isLoading = bool)
      )
    },
    viewUser(item) {
      this.userDetailTarget = item
      this.$bvModal.show('user-details')
    },
    hideModal() {
      this.userDetailTarget = null
    },
    viewSetPrize(row, hideSelectWinner) {
      if (hideSelectWinner) {
        this.setPrizeTarget = { ...row, hideSelectWinner }
      } else {
        this.setPrizeTarget = row
      }
      this.$bvModal.show('set-prize')
    },
    openInstantSetStatus(item, statusAfter) {
      this.instantStatusTarget = {
        statusAfter: statusAfter,
        number: item.ticket_number,
        status: item.status,
        order_id: item.order?.id,
        user: item.user,
        user_id: item.user_id,
        prize_amount: item.cost,
        prize_type: item.product_id ? 'product' : item.prize,
        product: item.product_id ? { id: item.product_id, name: item.prize, cost: item.cost } : null,
        reward: { id: item.user_reward_id, profiles: item.user_reward_profiles },
      }
      this.$bvModal.show('instant-set-status')
    },
  },
  created() {
    this.id = parseInt(this.$route.query.id)
    this.status = parseInt(this.$route.query.status)
    this.getRewardList()
  },
}
</script>
<style scoped>
::v-deep .modal-dialog {
  width: max-content;
  min-width: 50%;
  max-width: 75%;
}
</style>
