<template>
  <b-modal
    id="set-prize"
    :title="reward && reward.hideSelectWinner ? $t('action.set_prize') : `Set Live Draw Winner`"
    lazy
    centered
    title-class="w-100 d-flex justify-content-center align-self-center"
    header-class="py-2"
    footer-class="py-2"
    body-class="py-0"
    @shown="initForm(true)"
    @hidden="initForm(false)"
    :loading="isLoading"
    :ok-title="$t('action.submit')"
    :ok-disabled="formDisabled"
    @ok="onSubmit">
    <template v-if="reward">
      <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
      <div v-if="!reward.hideSelectWinner" class="w-100 d-flex flex-row flex-wrap align-items-center">
        <b-form-input v-model="queryParams['numbers']" type="search" :placeholder="$tc('order.ticket_number', 1)" @keyup.enter="onSearch" style="width: 136px" class="mt-0 mb-2 mr-2" />
        <b-button variant="basic" class="mt-0 mb-2" @click="onSearch">Search Ticket</b-button>
        <b-button variant="reset" class="mt-0 mb-2" @click="onReset"><b-icon-x class="mr-1" />{{ $t('action.reset') }}</b-button>
      </div>
      <b-table :fields="fields" :items="ticketList ? ticketList.data : []" primary-key="id" show-empty small hover responsive head-variant="light" sticky-header="100%" class="m-0 p-0">
        <template #cell(status)="{ value }"><TicketStatus :value="value" abbrev /></template>
        <template #cell(type)="{ value }"><TicketType :value="value" /></template>
        <template #cell(order)="{ value, item }">
          <router-link
            v-if="value"
            :to="{
              name: 'Order Details',
              query: { orderDetailsId: value.id, userId: item.user.id, userEmail: item.user.email, display_name: item.user.display_name, useBalance: item.use_balance, ip: value.remote_ip },
            }"
            class="action text-decoration-none"
            >{{ '#' + value.id }}
          </router-link>
          <b-badge v-else pill variant="danger">{{ $t('order.no_order') }}</b-badge>
        </template>
        <template #cell(user)="{ value }">
          <span v-if="value" class="action" @click="viewUser(value)">{{ value.name }}</span>
          <b-badge v-else pill variant="danger">{{ $t('user.no_user') }}</b-badge>
        </template>
      </b-table>
      <p v-if="ticket && ticket.user_id == 1" class="m-0 text-sm text-danger font-weight-normal">⚠️ The user, redra.w, is not valid.</p>

      <b-form id="set-prize" class="mt-4">
        <label>
          {{ $t('action.set_prize') }}
          <span v-if="reward.hideSelectWinner" class="text-danger">*</span>
          <span v-else>{{ $t('input.optional') }}</span>
        </label>
        <p class="m-0 text-sm text-slack font-weight-normal">⚠️ If the winner has selected a prize, specify details here or complete this later.</p>
        <b-form-radio-group v-model="reward_option" :required="reward.hideSelectWinner" class="mt-2 mb-0">
          <template v-if="['cash', 'cash_multiple'].includes(reward.product_type)">
            <b-form-radio value="one-off" class="allocate-checkbox w-100">
              <div class="flex-grow-0 flex-shrink-0 mt-1" style="width: 136px">
                {{ $t('product.one_off') }}
                <span v-if="reward_option == 'one-off'" class="ml-1 text-danger">*</span>
              </div>
              <b-input-group class="flex-grow-1 flex-shrink-1 pl-2 m-0">
                <b-form-input value="GBP" readonly style="max-width: 52px" />
                <b-form-input v-model="one_off" :disabled="existingAltCash > 0 || reward_option != 'one-off'" number type="number" step="0.01" min="0.01" class="px-1" style="max-width: 200px" />
              </b-input-group>
            </b-form-radio>
            <b-form-radio v-if="reward.payment_frequency" value="multi-pay" class="allocate-checkbox w-100 mt-3">
              <div class="flex-grow-0 flex-shrink-0 mt-1" style="width: 136px">
                {{ $t('product.multi_pymt') }}
                <span v-if="reward_option == 'multi-pay'" class="ml-1 text-danger">*</span>
              </div>
              <b-input-group class="flex-grow-1 flex-shrink-1 pl-2 m-0">
                <b-form-input v-model="multi_pay" :disabled="!!savedMultiPay || reward_option != 'multi-pay'" class="px-2" style="max-width: 252px" />
              </b-input-group>
            </b-form-radio>
          </template>

          <template v-else>
            <b-form-radio value="settle" class="allocate-checkbox w-100">
              <span class="flex-grow-0 flex-shrink-0 mt-1" style="width: 100%">Award the original prize to the winner</span>
            </b-form-radio>
            <b-form-radio value="cash" class="allocate-checkbox w-100 mt-3">
              <div class="flex-grow-0 flex-shrink-0 mt-1" style="width: 130px">
                Convert to Cash
                <span v-if="reward_option == 'cash'" class="ml-1 text-danger">*</span>
              </div>
              <b-input-group class="flex-grow-1 flex-shrink-1 pl-2 m-0">
                <b-form-input value="GBP" readonly style="max-width: 52px" />
                <b-form-input
                  v-model="convert_cash"
                  :disabled="existingAltCash > 0 || reward_option != 'cash'"
                  number
                  type="number"
                  step="0.01"
                  min="0.01"
                  :placeholder="$t('competition.cash_alt')"
                  class="px-1"
                  style="max-width: 200px" />
              </b-input-group>
            </b-form-radio>
            <b-form-radio value="credit" class="allocate-checkbox w-100 mt-2">
              <div class="flex-grow-0 flex-shrink-0 mt-1" style="width: 130px">
                Convert to Credit
                <span v-if="reward_option == 'credit'" class="ml-1 text-danger">*</span>
              </div>
              <b-input-group class="flex-grow-1 flex-shrink-1 pl-2 m-0">
                <b-form-input value="GBP" readonly style="max-width: 52px" />
                <b-form-input
                  v-model="convert_credit"
                  :disabled="existingAltCredit > 0 || reward_option != 'credit'"
                  number
                  type="number"
                  step="0.01"
                  min="0.01"
                  :placeholder="$t('competition.credit')"
                  class="px-1"
                  style="max-width: 200px" />
              </b-input-group>
            </b-form-radio>
          </template>
        </b-form-radio-group>

        <b-form-group
          v-if="(['cash', 'one-off'].includes(reward_option) && !existingAltCash > 0) || (reward_option == 'credit' && !existingAltCredit > 0)"
          label="Comment"
          label-class="form-label-required"
          class="mt-4 mb-0">
          <b-form-textarea v-model="comment" required :placeholder="$t('input.enter_reason')" />
        </b-form-group>
      </b-form>

      <p v-if="notice[0]" class="mt-2 mb-0 text-sm text-danger font-weight-normal">⚠️ {{ notice[0] }}</p>
    </template>
  </b-modal>
</template>
<script>
import TicketStatus from '@/components/TicketStatus.vue'
import TicketType from '@/components/TicketType.vue'
import axios from '@/plugins/axios'
import { API_LIST } from '@/utils/consts'
import { notifyError, notifySuccess } from '@/utils/index'
import { floatDivision, formatWith2Digits, formatWithCurrency, formatWithGBP } from '@/utils/numberUtils'
import { mapGetters } from 'vuex'

export default {
  name: 'SetPrize',
  components: {
    TicketStatus,
    TicketType,
  },
  props: {
    reward: Object,
    getList: Function,
    competitionId: [Number, String],
    viewUser: Function,
  },
  data() {
    return {
      isLoading: false,
      queryParams: { id: this.competitionId, numbers: null, page: 1, perPage: 10 },
      ticket: null,
      reward_option: null,
      one_off: null,
      multi_pay: null,
      convert_cash: null,
      convert_credit: null,
      comment: null,
    }
  },
  computed: {
    ...mapGetters({ ticketList: 'request/ticketList' }),

    fields() {
      return [
        { key: 'number', label: this.$tc('order.ticket_number', 1), stickyColumn: true },
        { key: 'user', label: this.$t('general.username') },
        { key: 'prize', formatter: () => this.reward?.prize },
        { key: 'prize_cost', formatter: () => formatWithCurrency(this.reward?.cost ?? 0, this.reward?.currency) },
        { key: 'status', label: this.$t('general.status') },
        { key: 'order', label: this.$tc('general.order', 1) },
        { key: 'type', label: this.$t('order.order_type') },
      ]
    },

    existingAltCash() {
      return Number(this.reward?.alternative_cash_amount ?? 0)
    },

    existingAltCredit() {
      return Number(this.reward?.alternative_credit_amount ?? 0)
    },

    selectDisabled() {
      return !this.ticket || this.ticket.user_id == 1
    },

    formDisabled() {
      // return false
      return (
        !this.reward ||
        this.selectDisabled ||
        (this.reward.hideSelectWinner && !this.reward_option) ||
        (this.reward_option == 'cash' && (!Number(this.convert_cash) > 0 || (!this.existingAltCash > 0 && !this.comment))) ||
        (this.reward_option == 'credit' && (!Number(this.convert_credit) > 0 || (!this.existingAltCash > 0 && !this.comment))) ||
        (this.reward_option == 'one-off' && (!Number(this.one_off) > 0 || (!this.existingAltCash > 0 && !this.comment)))
      )
    },

    notice() {
      if (!this.reward) {
        return ['', '']
      }
      if (!this.reward.hideSelectWinner && !this.selectDisabled && !this.reward_option) {
        return [`Set ${this.ticket.user.name} as the live draw winner, but do not set prize yet.`, `Set ${this.ticket.user.name} as the live draw winner successfully!`]
      }
      if (!this.formDisabled && this.reward_option) {
        const str =
          (this.reward.type == '1' && !this.reward.hideSelectWinner ? `Set ${this.ticket.user.name} as the live draw winner, and ` : '') +
          (this.reward_option == 'settle'
            ? 'award the original prize'
            : this.reward_option == 'cash'
            ? `convert the prize to cash ${formatWithGBP(this.convert_cash)}`
            : this.reward_option == 'credit'
            ? `convert the prize to credit ${formatWithGBP(this.convert_credit)}`
            : this.reward_option == 'one-off'
            ? `set the prize to one-off ${formatWithGBP(this.one_off)}`
            : this.reward_option == 'multi-pay'
            ? `set the prize to multiple payments ${this.savedMultiPay?.placeholder}`
            : '')
        const successMsg =
          (this.reward.type == '1' && !this.reward.hideSelectWinner ? `Set ${this.ticket.user.name} as the live draw winner, and ` : '') +
          (this.reward_option == 'settle'
            ? 'award the original prize successfully!'
            : this.reward_option == 'cash'
            ? `deposit cash ${formatWithGBP(this.convert_cash)} to ${this.ticket.user.name}'s wallet successfully!`
            : this.reward_option == 'credit'
            ? `deposit credit ${formatWithGBP(this.convert_credit)} to ${this.ticket.user.name}'s wallet successfully!`
            : this.reward_option == 'one-off'
            ? `deposit one-off cash ${formatWithGBP(this.one_off)} to ${this.ticket.user.name}'s wallet successfully!`
            : this.reward_option == 'multi-pay'
            ? `deposit the first payment ${formatWithGBP(this.savedMultiPay?.amount ?? 0)} to ${this.ticket.user.name}'s wallet successfully! (Remaining ${this.savedMultiPay?.toast})`
            : '')
        return [str.charAt(0).toUpperCase() + str.slice(1), successMsg.charAt(0).toUpperCase() + successMsg.slice(1)]
      }
      return ['', '']
    },

    savedMultiPay() {
      return this.getMultiPay()
    },
  },
  methods: {
    initForm(show) {
      this.$store.dispatch('request/mutateState', { property: 'ticketList', with: null })
      this.queryParams.numbers = null
      this.reward_option = null
      this.one_off = formatWith2Digits(this.reward?.alternative_cash_amount ?? 0)
      this.multi_pay = this.savedMultiPay?.placeholder ?? null
      this.convert_cash = formatWith2Digits(this.reward?.alternative_cash_amount ?? 0)
      this.convert_credit = formatWith2Digits(this.reward?.alternative_credit_amount ?? 0)
      this.comment = null
      if (!show) {
        this.$emit('update:reward', null)
      } else {
        if (this.reward?.hideSelectWinner) {
          this.queryParams.numbers = this.reward.ticket_number
          this.getTicketList()
        } else {
          this.ticket = null
        }
      }
    },

    getMultiPay() {
      if (this.reward?.payment_frequency && Number(this.reward.payment_numbers) > 0 && Number(this.reward.cost) > 0) {
        const amount = floatDivision(this.reward.cost, this.reward.payment_numbers)
        return {
          amount: amount,
          placeholder: `${formatWithGBP(amount)} ${this.reward.payment_frequency} × ${this.reward.payment_numbers} times`,
          toast: `${formatWithGBP(amount)} ${this.reward.payment_frequency} × ${this.reward.payment_numbers - 1} times`,
        }
      } else {
        return null
      }
    },

    getTicketList() {
      this.isLoading = true
      axios
        .get(API_LIST.get.ticketList, { params: { ...this.queryParams, numbers: `[${this.queryParams.numbers}]` } })
        .then((res) => {
          this.$store.dispatch('request/mutateState', { property: 'ticketList', with: res?.data?.data ?? null })
          this.ticket = res?.data?.data?.data?.[0]
        })
        .catch((err) => {
          notifyError(err, this.$t('notify.unknown_err'))
          this.$store.dispatch('request/mutateState', { property: 'ticketList', with: null })
          this.ticket = null
        })
        .finally(() => (this.isLoading = false))
    },

    onSearch() {
      this.queryParams.page = 1
      this.getTicketList()
    },

    onReset() {
      this.queryParams.numbers = null
      this.ticket = null
      this.$store.dispatch('request/mutateState', { property: 'ticketList', with: null })
    },

    onSubmit() {
      this.isLoading = true
      const { hideSelectWinner } = this.reward
      const successMsg = hideSelectWinner ? this.notice[1] ?? 'Set prize successfully!' : 'Set live draw winner successfully!'
      const errorMsg = hideSelectWinner ? 'Failed to set prize!' : 'Failed to set live draw winner!'
      const params = { competition_reward_id: this.reward.id }
      if (!hideSelectWinner) {
        params['competition_ticket_id'] = this.ticket.id
      }
      if (this.reward_option) {
        params['comment'] = this.comment
        switch (this.reward_option) {
          case 'cash':
            params.reward_option = 'cash'
            params.reward_amount = this.convert_cash
            break
          case 'credit':
            params.reward_option = 'credit'
            params.reward_amount = this.convert_credit
            break
          case 'one-off':
            params.reward_option = 'cash'
            params.reward_amount = this.one_off
            break
          case 'multi-pay':
            params.reward_option = 'settle'
            break
          case 'settle':
            params.reward_option = 'settle'
            break
        }
      }
      axios
        .post(hideSelectWinner ? API_LIST.post.setPrize : API_LIST.post.selectWinner, params)
        .then((res) => {
          if (res?.status == 200) {
            notifySuccess(res, successMsg)
            this.getList()
            this.$bvModal.hide('set-prize')
          }
        })
        .catch((err) => notifyError(err, errorMsg))
        .finally(() => (this.isLoading = false))
    },

    formatWithGBP,
    formatWithCurrency,
  },
  created() {},
}
</script>

<style lang="scss">
.allocate-checkbox {
  padding-left: 2rem;
  label {
    width: 100%;
    display: flex;
  }
  label::before,
  label::after {
    left: -2rem;
    top: 0.5rem;
  }
}
</style>
