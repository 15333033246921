var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('card',{staticClass:"table-custom",attrs:{"body-classes":"p-0 d-flex flex-column"}},[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"is-full-page":false}}),_c('b-table',{staticClass:"my-5 mx-0 p-0",attrs:{"fields":_vm.fields,"items":_vm.items,"show-empty":"","small":"","hover":"","responsive":"","head-variant":"light"},scopedSlots:_vm._u([{key:"cell(key)",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(Array.isArray(item))?_vm._l((item),function(trigger,j){return _c('div',{key:("trigger-" + index + "-" + j),class:j == 0 ? '' : 'border-top mt-1 pt-1'},[_vm._v(" "+_vm._s(trigger.key ? trigger.key.replaceAll('_', ' ') + (trigger.key.includes('margin') ? ' (%)' : ' (£)') : '-')+" ")])}):(typeof item == 'object')?_vm._l((Object.entries(item)),function(ref,j){
var key = ref[0];
return _c('div',{key:("trigger-" + index + "-" + key),class:j == 0 ? '' : 'border-top mt-1 pt-1'},[_vm._v(" "+_vm._s(key ? key.replaceAll('_', ' ') + (key.includes('margin') ? ' (%)' : ' (£)') : '-')+" ")])}):_vm._e()]}},{key:"cell(value)",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(Array.isArray(item))?_vm._l((item),function(trigger,j){return _c('div',{key:("trigger-" + index + "-" + j),class:j == 0 ? '' : 'border-top mt-1 pt-1'},[_vm._v(" "+_vm._s(trigger.value)+" ")])}):(typeof item == 'object')?_vm._l((Object.entries(item)),function(ref,j){
var key = ref[0];
var trigger = ref[1];
return _c('div',{key:("trigger-" + index + "-" + key),class:j == 0 ? '' : 'border-top mt-1 pt-1'},[_vm._v(" "+_vm._s(trigger.value)+" ")])}):_vm._e()]}},{key:"cell(description)",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(Array.isArray(item))?_vm._l((item),function(trigger,j){return _c('div',{key:("trigger-descr-" + index + "-" + j),class:j == 0 ? '' : 'border-top mt-1 pt-1'},[_vm._v(" "+_vm._s(trigger.description)+" ")])}):(typeof item == 'object')?_vm._l((Object.entries(item)),function(ref,j){
var key = ref[0];
var trigger = ref[1];
return _c('div',{key:("trigger-" + index + "-" + key),class:j == 0 ? '' : 'border-top mt-1 pt-1'},[_vm._v(" "+_vm._s(trigger.description)+" ")])}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }