<template>
  <card body-classes="p-0 d-flex flex-column" class="table-custom">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" />
    <EntrySearchInput :queryParams="queryParams" type="video" :onSearch="onSearch" />
    <b-table :fields="fields" :items="items" show-empty :empty-text="$t('notify.table_no_records')" small hover striped responsive head-variant="light" class="m-0 p-0" sticky-header="100%">
      <template #cell(store_id)="{ value }"><store-badge :store_id="value" /></template>
      <template #cell(url)="{ value }">
        <b-link :disabled="!isValidHttpUrl(value)" :href="value" target="_blank" v-b-tooltip.hover.topright="$t('entry.upload_notice[0]')" :class="{ 'text-muted': !isValidHttpUrl(value) }">
          <b-icon-link45deg class="mr-1" />{{ $t('entry.link') }}
        </b-link>
      </template>
      <template #cell(status)="{ value }">
        <b-badge :variant="['secondary', 'success'][value]">{{ $t('entry.status_list')[value] }}</b-badge>
      </template>
      <template #cell(id)="{ value, item }">
        <b-button @click="onEdit(item)" variant="basic"><b-icon-pencil class="mr-1" />{{ $t('action.edit') }}</b-button>
        <b-button @click="handleStatus(value, item.status)" :disabled="item.status != 0" variant="basic">{{ $t('action.publish') }}<b-icon-cloud-upload class="ml-1" /></b-button>
      </template>
    </b-table>
    <paginate v-if="items.length > 0" :queries="queryParams" :fetcher="getVideoDrawList" :total="videoDrawList.total" class="card-footer" />

    <confirm-modal
      id="confirm-publish-vid"
      :title="$t('entry.confirm_publish')"
      :message="$t('entry.publish_notice[0]', { status: $t(`entry.status_list[${statusParams.status == 1 ? 1 : 0}]`) })"
      :isLoading="modalLoading"
      :onSubmit="onUpdate" />
    <VideoDrawUpsert :title="$t('draw.edit_vid_draw')" :drawDetails.sync="actionTarget" :getList="getVideoDrawList" />
  </card>
</template>
<script>
import EntrySearchInput from '@/components/EntrySearchInput.vue'
import VideoDrawUpsert from '@/components/VideoDrawUpsert.vue'
import { formatLocalDateTime } from '@/utils/dateTimeUtils'
import { isValidHttpUrl } from '@/utils/index'
import { mapGetters } from 'vuex'

export default {
  name: 'VideoList',
  components: {
    EntrySearchInput,
    VideoDrawUpsert,
  },
  data() {
    return {
      isLoading: true,
      fields: [
        { key: 'competition.title', label: this.$t('competition.comp_title'), stickyColumn: true, tdAttr: { style: 'white-space: normal; min-width: 14rem' } },
        { key: 'title', label: this.$t('draw.draw_title'), tdAttr: { style: 'white-space: normal; min-width: 14rem' } },
        { key: 'store_id', label: this.$tc('general.store', 1), class: 'text-center' },
        { key: 'draw_date', label: this.$t('draw.draw_date'), formatter: (val) => formatLocalDateTime(val), sortable: true },
        { key: 'status', label: this.$t('general.status'), class: 'text-center', tdClass: 'text-uppercase' },
        { key: 'id', label: this.$tc('general.action', 1), class: 'text-center px-2' },
        { key: 'url', label: this.$t('general.details'), class: 'text-center' },
        { key: 'iframe', label: this.$t('draw.iframe_url'), formatter: (val) => (val ? val : '-'), tdClass: 'text-truncate' },
        { key: 'updated_at', label: this.$t('general.updated_at'), formatter: (val) => formatLocalDateTime(val), sortable: true },
      ],
      queryParams: {
        store_id: null,
        title: null,
        status: null,
        page: 1,
        perPage: 10,
        start: null,
        end: null,
        ...this.$route.params?.prev,
        competition_id: this.$route.params?.comp_id,
      },
      statusParams: {},
      modalLoading: false,
      actionTarget: null,
    }
  },
  computed: {
    ...mapGetters({ videoDrawList: 'request/videoDrawList', allowMultiStores: 'allowMultiStores', selfStore: 'store' }),
    items() {
      return this.videoDrawList?.data ?? []
    },
  },
  methods: {
    isValidHttpUrl,
    handleStatus(id, status) {
      this.modalLoading = false
      this.statusParams['id'] = id
      this.$bvModal.show('confirm-publish-vid')
      this.statusParams['status'] = status === 1 ? 0 : 1
    },
    onEdit(row) {
      this.actionTarget = row
      this.actionTarget['targetStore'] = this.allowMultiStores ? this.queryParams.store_id ?? row.store_id : this.selfStore
      this.$bvModal.show('video-draw-upsert')
    },
    onUpdate() {
      this.modalLoading = true
      this.$store
        .dispatch('request/updateVideoDrawStatus', { ...this.statusParams, successMsg: this.$t('notify.success'), errorMsg: this.$t('draw.save_notice[1]', { action: this.$t('action.update') }) })
        .then(() => {
          this.modalLoading = false
          this.getVideoDrawList()
          this.$bvModal.hide('confirm-publish-vid')
        })
        .catch(() => (this.modalLoading = false))
    },
    getVideoDrawList(_params) {
      this.isLoading = true
      const params = { ..._params }
      for (const key in this.queryParams) {
        if (this.queryParams[key] || typeof this.queryParams[key] == 'number') {
          params[key] = this.queryParams[key]
        }
      }
      this.$store
        .dispatch('request/getVideoDrawList', params)
        .then(() => (this.isLoading = false))
        .catch(() => (this.isLoading = false))
    },
    onSearch() {
      this.queryParams['page'] = 1
      this.getVideoDrawList({ errorMsg: this.$t('draw.get_list_failed') })
    },
  },
  created() {
    this.queryParams.store_id = this.allowMultiStores ? null : this.selfStore
    this.getVideoDrawList()
  },
  beforeRouteLeave(to, from, next) {
    this.$set(from.params, 'preservedParams', this.queryParams)
    return next()
  },
}
</script>
